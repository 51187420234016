import React from "react"
import Img from "gatsby-image"
import styles from "./Deliverables.module.scss"
import { frontmatterPathBuilder } from "../../helpers/pathBuilders"

export default props => {
    const page = props.pageData
    const images = props.imageItemData
    const NonStretchedImage = props => {
        let normalizedProps = props
        if (props.fluid && props.fluid.presentationWidth) {
            normalizedProps = {
                ...props,
                style: {
                    ...(props.style || {}),
                    maxWidth: props.fluid.presentationWidth,
                    margin: "0 auto", // Used to center the image
                },
            }
        }

        return <Img {...normalizedProps} />
    }

    return (
        <section className={styles.deliverables}>
            <h2 className={styles.deliverables__title}>
                Project Deliverables for {frontmatterPathBuilder(page).title}
            </h2>
            <ul className={styles.deliverables__list}>
                {images.map(({ node }) => (
                    <li
                        key={node.id}
                        className={styles.deliverables__list_item}
                    >
                        <NonStretchedImage
                            fluid={node.childImageSharp.fluid}
                            width={node.childImageSharp.fluid.presentationWidth}
                            height={
                                node.childImageSharp.fluid.presentationHeight
                            }
                        />
                    </li>
                ))}
            </ul>
        </section>
    )
}
