import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styles from "./Gallery.module.scss"
import {
    pagePathBuilder,
    frontmatterPathBuilder,
} from "../../helpers/pathBuilders"

export default props => {
    const items = props.items

    return (
        <section>
            <h2 className={styles.gallery__title}>And Also:</h2>
            <ul className={`${styles.gallery} ${styles.gallery____small}`}>
                {items.map(({ node }) => (
                    <li key={node.id} className={styles.gallery__item}>
                        <Link
                            to={pagePathBuilder(node)}
                            className={styles.gallery__link}
                        >
                            <div className={styles.gallery__text_wrapper}>
                                <span className={styles.gallery__item_title}>
                                    {frontmatterPathBuilder(node).title}
                                </span>
                            </div>
                            {frontmatterPathBuilder(node).thumbnail && (
                                <Img
                                    fluid={
                                        frontmatterPathBuilder(node).thumbnail
                                            .childImageSharp.fluid
                                    }
                                    className={styles.gallery__item_image}
                                />
                            )}
                        </Link>
                    </li>
                ))}
            </ul>
        </section>
    )
}
