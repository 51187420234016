import React from "react"
import { motion } from "framer-motion"
import "./Layout.scss"
import { variantsSecondary, variantsTertiary } from "../helpers/motionVariants"

export default props => (
    <>
        <article className="layout">
            {props.heading ? props.heading : null}

            {props.pagination ? (
                <section className="layout__grid____top">
                    {props.pagination ? props.pagination : null}
                </section>
            ) : null}

            {props.cover ? props.cover : null}

            <section className="layout__grid">
                {props.main || props.aside ? (
                    <section className="layout__grid____content">
                        {props.main ? (
                            <motion.main
                                className="layout__grid____primary rich_text"
                                variants={variantsSecondary}
                                initial="hidden"
                                animate="enter"
                            >
                                {props.main}
                            </motion.main>
                        ) : null}

                        {props.aside ? (
                            <motion.div
                                className="layout__grid____secondary"
                                variants={variantsTertiary}
                                initial="hidden"
                                animate="enter"
                            >
                                {props.aside}
                            </motion.div>
                        ) : null}
                    </section>
                ) : null}
            </section>

            {props.gallery ? props.gallery : null}
        </article>

        {props.deliverables ? props.deliverables : null}

        <article className="layout layout____secondary">
            <section className="layout__grid">
                {props.pagination ? props.pagination : null}

                {props.furtherLinks ? (
                    <section>{props.furtherLinks}</section>
                ) : null}
            </section>
        </article>
    </>
)
