import React from "react"
import kebabCase from "lodash/kebabCase"
import styles from "./Aside.module.scss"

export default props => {
    const Client = () =>
        props.client ? (
            <li className={styles.aside__list_item} key="client">
                <span>
                    <span className={styles.aside__list_item_header}>
                        Client:
                    </span>{" "}
                    {props.client}
                    <LiveSite />
                </span>
            </li>
        ) : null
    const LiveSite = () =>
        props.liveLink.linkUrl && props.liveLink.linkSafe ? (
            <span>
                {" "}
                -{" "}
                <a href={props.liveLink.linkUrl} className={styles.aside__link}>
                    {props.liveLink.linkSafe}
                </a>
            </span>
        ) : null
    const TagLister = () =>
        props.tags.map(tag => (
            <li className={styles.aside__tags_list_item} key={tag}>
                <a
                    href={kebabCase(tag)}
                    className={`${styles.aside__link} ${styles.aside__link____tag}`}
                    key={tag}
                >
                    {tag}
                </a>
            </li>
        ))
    const Tags = () =>
        props.tags ? (
            <li className={styles.aside__list_item} key="tags">
                <span>
                    <span className={styles.aside__list_item_header}>
                        Tags:
                    </span>{" "}
                    <ul
                        className={`${styles.aside__list} ${styles.aside__tags_list}`}
                    >
                        {TagLister(props.tags)}
                    </ul>
                </span>
            </li>
        ) : null
    const Collaborators = () =>
        props.collaborators && props.collaborators.name ? (
            <li className={styles.aside__list_item} key="collaborators">
                <p>
                    <span className={styles.aside__list_item_header}>
                        Collaborator:
                    </span>{" "}
                    {props.collaborators.linkUrl ? (
                        <a
                            href={props.collaborators.linkUrl}
                            className={styles.aside__link}
                        >
                            {props.collaborators.name}
                        </a>
                    ) : (
                        props.collaborators.name
                    )}
                </p>
            </li>
        ) : null

    return (
        <aside className={`${styles.aside____work}`}>
            <ul className={styles.aside__list}>
                <Client />
                <Collaborators />
                <Tags />
            </ul>
        </aside>
    )
}
