import React from "react"
import Metadata from "../components/Metadata/Metadata"
import { graphql } from "gatsby"
import LayoutWorkSingle from "../layout/LayoutWorkSingle"
import Heading from "../components/Heading/Heading"
import AsideWorkSingle from "../components/Aside/AsideWorkSingle"
import Pagination from "../components/Pagination/Pagination"
import CoverImage from "../components/CoverImage/CoverImage"
import Deliverables from "../components/Deliverables/Deliverables"
import GallerySmall from "../components/Gallery/GallerySmall"
import { frontmatterPathBuilder } from "../helpers/pathBuilders"

export const query = graphql`
    query($relativeDirectory: String) {
        page: markdownRemark(
            frontmatter: { pathName: { eq: $relativeDirectory } }
        ) {
            html
            frontmatter {
                title
                description
                cover {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 90) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                tags
                client
                liveLink {
                    linkUrl
                    linkSafe
                }
                collaborators {
                    name
                    linkUrl
                }
            }
        }
        allMarkdownRemark(
            filter: { fields: { sourceInstanceName: { eq: "work-single" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        pathDirectory
                        pathName
                        title
                        date(formatString: "DD MMMM, YYYY")
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 240) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    excerpt
                }
            }
        }
        allFile(
            filter: {
                extension: { regex: "/(jpg)|(jpeg)|(png)|(webp)|(svg)|(gif)/" }
                sourceInstanceName: { eq: "work-single" }
                name: { nin: ["thumb", "cover", "hero"] }
                relativeDirectory: { eq: $relativeDirectory }
            }
            sort: { fields: name }
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        fluid(
                            maxWidth: 1800
                            sizes: "320, 760, 1200, 1800"
                            quality: 95
                        ) {
                            ...GatsbyImageSharpFluid_withWebp
                            srcSet
                            src
                            sizes
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`

export default ({ data, pageContext }) => {
    const page = data.page
    const deliverableItems = data.allFile.edges
    const galleryItems = data.allMarkdownRemark.edges
    const { prev, next } = pageContext
    const Main = () => <div dangerouslySetInnerHTML={{ __html: page.html }} />

    return (
        <>
            <Metadata title={page.frontmatter.title} />
            <LayoutWorkSingle
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={frontmatterPathBuilder(page).title}
                        headingDescription={
                            frontmatterPathBuilder(page).description
                        }
                        returnLink={true}
                    />
                }
                pagination={<Pagination prev={prev} next={next} />}
                cover={<CoverImage pageData={page} />}
                main={<Main />}
                aside={
                    <AsideWorkSingle
                        client={frontmatterPathBuilder(page).client}
                        liveLink={frontmatterPathBuilder(page).liveLink}
                        tags={frontmatterPathBuilder(page).tags}
                        collaborators={
                            frontmatterPathBuilder(page).collaborators
                        }
                    />
                }
                deliverables={
                    <Deliverables
                        pageData={page}
                        imageItemData={deliverableItems}
                    />
                }
                furtherLinks={<GallerySmall items={galleryItems} />}
            />
        </>
    )
}
