import React from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import styles from "./CoverImage.module.scss"
import { frontmatterPathBuilder } from "../../helpers/pathBuilders"
import { variantsSecondary } from "../../helpers/motionVariants"

export default props => {
    const page = props.pageData

    return (
        frontmatterPathBuilder(page).cover && (
            <motion.div
                className={styles.cover_image}
                variants={variantsSecondary}
                initial="hidden"
                animate="enter"
            >
                <Img
                    fluid={
                        frontmatterPathBuilder(page).cover.childImageSharp.fluid
                    }
                    width={
                        frontmatterPathBuilder(page).cover.childImageSharp.fluid
                            .presentationalWidth
                    }
                    height={
                        frontmatterPathBuilder(page).cover.childImageSharp.fluid
                            .presentationalHeight
                    }
                    className={styles.cover_image__image}
                />
            </motion.div>
        )
    )
}
